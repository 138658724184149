<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="模板名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="template_name">
              <a-input v-model="form.template_name" placeholder="请输入模板名称..." />
            </a-form-model-item>
          </a-col>
          <!-- <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="IOS编码" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="iso2">
              <a-input v-model="form.iso2" placeholder="请输入IOS编码..." />
            </a-form-model-item>
          </a-col> -->

          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="模板状态" :labelCol='labelcol' :wrapperCol="wrappercol" prop="is_delete">
              <a-select v-model="form.is_delete" placeholder="请选择模板状态..." :options='is_delete'>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
            <a-form-model-item style="text-align:right;">

              <a-button type="primary" @click="search">
                搜索
              </a-button>
              <a-button @click="reset('ruleForm')">重置</a-button>

            </a-form-model-item>
          </a-col>
      </a-form-model>
    </div>
    <div class="mainContent" style="display: flex;justify-content: flex-end;">
      <a-button type="primary" @click="onAdd">
        添加模板
      </a-button>
    </div>
    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :scroll="{ x: true }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.template_id"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >
        <!-- 状态单元格 -->
        <template slot="is_delete" slot-scope="data,record">
          <a-tag :color="record.is_delete=='0'?'green':'red'">
              {{record.is_delete|statusFilter}}
          </a-tag>
        </template>
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

              <span>

                <a-button size="small" type="primary"  @click="onEdit(data)">编辑</a-button>

                <a-popconfirm v-if="data.is_delete=='0'" title="是否禁用此模板?" @confirm="() => onDisable(data)">
                  <a-button size="small" type="danger">禁用</a-button type="primary" >
                </a-popconfirm>
                <a-popconfirm v-else title="是否启用此模板?" @confirm="() => onEnable(data)">
                  <a-button size="small" type="primary" class="greenBtn">启用</a-button type="primary">
                </a-popconfirm>

              </span>

        </template>
      </a-table>
    </div>

  <!-- 修改帐号信息模态框 -->
  <a-modal

      :title="title"
      :visible="editModalVisible"
      :confirm-loading="confirmLoading"
      @ok="save"
      @cancel="cancel"
    >
      <!-- 编辑 -->
      <a-form-model
        ref="editRef"
        :model="editForm"
        :rules="editFormRules"
        :label-col="{span:7}"
        :wrapper-col="{span:17}"
      >
        <a-form-model-item label="模板名称" prop="template_name">
          <a-input v-model="editForm.template_name" placeholder="请输入模板名称..."/>
        </a-form-model-item>

        <a-form-model-item label="模板描述" prop="description">
          <a-input v-model="editForm.description" placeholder="请输入模板描述..."/>
        </a-form-model-item>


        <a-form-model-item label="状态" prop="is_delete" v-if="title=='编辑模板'">
          <a-select v-model.Number="editForm.is_delete" placeholder="请选择模板状态..." :options='is_delete_edit'>

          </a-select>
        </a-form-model-item>
      </a-form-model>
  </a-modal>

  </div>
</template>
<script>
import {getTemplatesList,editTemplates,addTemplates,deleteTemplates} from "@/axios/templates.js"
import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';
const columns = [//列描述
  {
    align:'center',
    title: '模板ID',
    dataIndex: 'template_id',
  },
  {
    align:'center',
    title: '模板名称',
    dataIndex: 'template_name',
  },
  {
    align:'center',
    title: '模板描述',
    dataIndex: 'description',
  },

  // {
  //   align:'center',
  //   title: 'IOS编码',
  //   dataIndex: 'iso2',
  // },

  {
    align:'center',
    title: '状态',
    dataIndex: 'is_delete',
    scopedSlots: { customRender: 'is_delete', },
  },
  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {
  filters: {

    statusFilter(status) {
      const statusMap = {
        0: '启用',
        1: '禁用',
      }

      return statusMap[status]

    },
  },
  mixins: [myMixin],
  data() {

    return {
      is_delete_edit: [
        {
            value: 0,
            label: '启用'
        },
        {
            value: 1,
            label: '禁用'
        },
      ],
      is_delete: [
        {
            value: -1,
            label: '全部'
        },
        {
            value: 0,
            label: '启用'
        },
        {
            value: 1,
            label: '禁用'
        },
      ],

      form: {
        page_size: 10,
        page:1,
        template_name: '',
        is_delete:-1,
        // iso2:''
      },

      columns,//列描述

      confirmLoading:false,//编辑模态框
      editModalVisible:false,
      editForm:{
        template_name:'',
        description:'',
        is_delete:-1,
      },
      editFormRules: {
        template_name: [
          { required: true, message: '请输入模板名', trigger: 'blur' },
        ],
        is_delete: [
          { required: true, message: '请选择用户状态', trigger: 'change' },
        ],
      },

      title:'编辑模板',
      languagesList:[]
    }

  },
  mounted() {
    let that=this
    this.fetch();

  },
  methods:{

      async fetch (params = {}) {
        this.loading = true;
        let data=await getTemplatesList({...params,...this.form})
        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = data.data.total;
        this.data = data.data.list;
        this.pagination = pagination;
      },

      //添加用户数据
      onAdd(){
        this.editModalVisible=true
        this.title='添加模板'
      },

      //禁用模板
      async onDisable(data){
        data.is_delete=1
        let resule=await editTemplates({...data});
          if(resule.status==1){
            this.$message.success(resule.errmsg);
            this.fetch()
          }else{
            this.$message.error(resule.errmsg);
        }
      },
      //启用模板
      async onEnable(data){
        data.is_delete=0
        console.log(data,'data')
        let resule=await editTemplates({...data});
          if(resule.status==1){
            this.$message.success(resule.errmsg);
            this.fetch()
          }else{
            this.$message.error(resule.errmsg);
        }
      },
      //编辑保存用户数据
      onEdit(data){
        this.title='编辑模板'
        this.editModalVisible=true
        this.$nextTick(()=>{
           this.editForm={...data}
        })
        console.log(this.editForm,'data')

      },
      save(){
        this.$refs.editRef.validate( async valid => {
        if (valid) {
          this.confirmLoading = true;
          let resule
          if(this.title=='添加模板'){
            const {is_delete,...rest} = this.editForm;
            resule=await addTemplates(rest);
          }else{
            resule=await editTemplates({...this.editForm});
          }

          this.confirmLoading = false;
          if(resule.status==1){
            console.log(resule)
            this.$message.success(resule.errmsg);
            this.editModalVisible = false;
            this.$refs.editRef.resetFields();
            this.fetch();
          }else{
            this.$message.error(resule.errmsg);
          }

        } else {
          return false;
        }
      });


      },
      cancel(){
        this.$refs.editRef.resetFields();
        this.editModalVisible = false;
      },
    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
</style>
